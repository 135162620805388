<template>
  <div class="search">
    <div class="search_lines">
      <div class="type1">
      <span>竞价交易</span>
        <span
          v-for="(i, index) in bidPriceList"
          :key="index"
          :class="
            activeClass5 == index || hoverClass5 == index ? 'myactived' : ''
          "
          @click="getactiveClass(5, index, i)"
          v-on:mouseover="gethoverClass(5, index,i)"
          v-on:mouseout="closeClass(5)"
          >{{ i.name }}<span v-if="i.children.length > 0">&#8681;</span></span
        >
      </div>
      <div class="type1" v-if="oneListIsShow">
        <span>一级分类：</span>
        <span
          v-for="(i, index) in oneList"
          :key="index"
          :class="
            activeClass == index || hoverClass == index ? 'myactived' : ''
          "
          @click="getactiveClass(1, index, i)"
          v-on:mouseover="gethoverClass(1, index,i)"
          v-on:mouseout="closeClass(1)"
          >{{ i.name }}<span v-if="i.children.length > 0">&#8681;</span></span
        >
      </div>
      <div class="type1" v-if="fjzcdis">
        <span>二级分类：</span>
        <span
          v-for="(i, index) in twoList"
          :key="index"
          :class="
            activeClass4 == index || hoverClass4 == index ? 'myactived' : ''
          "
          @click="getactiveClass(4, index, i)"
          v-on:mouseover="gethoverClass(4, index)"
          v-on:mouseout="closeClass(4)"
          >{{ i.name }}</span
        >
      </div>
      <div class="type2">
        <span>标的所在地：</span>
        <span
          v-for="(i, index) in a1s"
          :key="index"
          :class="
            activeClass99 == index || hoverClass99 == index ? 'myactived' : ''
          "
          @click="getactiveClass(99, index, i)"
          v-on:mouseover="gethoverClass(99, index,e)"
          v-on:mouseout="closeClass(99)"
          >{{ i.name }}</span
        >
        <span>
          <el-select
            v-model="provincecode"
            @change="selectProvince"
            filterable
            remote
            placeholder="请选择省份"
            style="flex: 1"
          >
            <el-option
              v-for="itemp in provincelist"
              :key="itemp.code"
              :label="itemp.province"
              :value="itemp.code"
            /> </el-select
        ></span>
        -
        <span>
          <el-select
            v-model="citycode"
            @change="selectCity"
            filterable
            remote
            placeholder="请选择市"
            style="flex: 1"
            :disabled="cityflg"
          >
            <el-option
              v-for="itemc in citylist"
              :key="itemc.code"
              :label="itemc.city"
              :value="itemc.code"
            /> </el-select
        ></span>
        -
        <span>
          <el-select
            v-model="towncode"
            filterable
            remote
            clearable
            placeholder="请选择县区"
            style="flex: 1"
            :disabled="townflg"
            @change="selectTown"
          >
            <el-option
              v-for="itemt in townlist"
              :key="itemt.code"
              :label="itemt.town"
              :value="itemt.code"
            /> </el-select
        ></span>
        <!-- </span> -->
      </div>
      <div class="type1">
        <span>项目状态：</span>
        <span
          v-for="(i, index) in a2"
          :key="index"
          :class="
            activeClass2 == index || hoverClass2 == index ? 'myactived' : ''
          "
          @click="getactiveClass(2, index, i)"
          v-on:mouseover="gethoverClass(2, index)"
          v-on:mouseout="closeClass(2)"
          >{{ i.name }}</span
        >
      </div>
      <div class="type1">
        <span>排序：</span>
        <button
          v-for="(i, index) in a3"
          :key="index"
          @click="buttonsort(index, i)"
        >
          <span :class="index == activeClass3 ? 'myactivedbt' : ''">
            {{ i.name }}
            <i
              v-if="
                index === activeClass3 &&
                showflg &&
                activeClass3show &&
                index !== 0
              "
              class="el-icon-top a"
            ></i>
            <i
              v-if="
                index === activeClass3 &&
                showflg &&
                !activeClass3show &&
                index !== 0
              "
              class="el-icon-bottom b"
            ></i>
          </span>
        </button>
        <button class="resetbt" @click="reset">重置</button>
      </div>
    </div>
  </div>
</template>

<script>
import { regionDataPlus, CodeToText } from "element-china-area-data";
import UploadService from "../../services/UploadFilesService";
export default {
  data() {
    return {
      index99: 88,
      provincelist: [],
      citylist: [],
      townlist: [],
      provincecode: "",
      citycode: "",
      towncode: "",
      cityflg: true,
      townflg: true,
      readonly1: false,

      fjzcdis: false,
      options: regionDataPlus, // 省市县
      selectedOptions: [], // 省市县
      activeClass: "", // 分类
      activeClass5: "",
      hoverClass5: "",
      hoverClass: "", // 分类
      activeClass2: "", // 拍卖状态
      hoverClass2: "", // 拍卖状态
      hoverClass99: "", // 地区不限
      activeClass4: "", // 排序按钮
      activeClass3: "", // 二级分类
      activeClass99: "", // 地区不限
      activeClass3show: true, // 排序按钮
      showflg: false, // 排序按钮
      a3: [
        { name: "综合排序", type: "0", classid: 1 },
        // { name: "价格排序", type: "1", classid: 2 },
        // { name: "出价次数", type: "2", classid: 3 },
        // { name: "开始时间", type: "3", classid: 4 },
        // { name: "结束时间", type: "3", classid: 4 },
      ],
      a2: [
        { name: "不限", type: "", classid: 1 },
        { name: "公示期", type: 5, classid: 1 },
        { name: "即将竞价", type: 0, classid: 1 },
        { name: "竞价中", type: 1, classid: 2 },
        { name: "已成交", type: 2, classid: 4 },
      ],
      a1: [
        { name: "不限", type: "", classid: 1 },
        { name: "废旧资材", type: "11", classid: 1 },
        { name: "闲废设备", type: "22", classid: 1 },
        { name: "闲废车辆", type: "33", classid: 1 },
        { name: "工业固废", type: "44", classid: 1 },
        { name: "租赁", type: "66", classid: 1 },
        { name: "煤系产品", type: "99", classid: 1 },
        { name: "其他", type: "55", classid: 1 },
      ],
      a1s: [{ name: "不限", type: "", classid: 1 }],
      a11: [
        { name: "综合类", type: "a", classid: 1 },
        { name: "废钢", type: "b", classid: 1 },
        { name: "废铁", type: "c", classid: 1 },
        { name: "废铜", type: "d", classid: 1 },
        { name: "橡胶", type: "e", classid: 1 },
        { name: "其他", type: "f", classid: 1 },
      ],
      aNewList: [
        { name: "全部", type: "1", classid: 1 },
        { name: "闲废竞拍", type: "2", classid: 1 },
        { name: "废产品竞拍", type: "3", classid: 1 },
        { name: "其他", type: "4", classid: 1 },
      ],
      forsearch: {
        outbidstate: "",
        // targettypecascader: "",
        provincecode: "",
        city: "",
        town: "",
      },
      type1: "",
      type2: "",
      type5:'',
      bidPriceList:[],
      oneList:[],
      oneListIsShow:false,
      twoList:[]
    };
  },
  mounted() {
    // this.getArealist('0', '')
    this.getlist();

  },
  created() {
    this.getArealist("0", "");
  },
  methods: {
    getlist() {
      this.$fetch("/shoppingui/ectargettype/list", {}, true).then((res)=>{
        if(res.code == 0){
          this.bidPriceList = res.data;
          this.$nextTick(()=>{
            let code = this.$route.query.code;
            let indexList = this.$route.query.index.split(",");
            this.forsearch.targettypecascader = code
            //展示一级
            this.oneListIsShow = true
            //主菜单选中
            this.activeClass5 = indexList[0];
            this.hoverClass5 = indexList[0];
            this.oneList = this.bidPriceList[indexList[0]].children;
            //一级菜单选中
            this.activeClass = indexList[1];
            this.hoverClass = indexList[1];
            if(indexList.length == 3) {
              this.fjzcdis = true;
              this.activeClass4 = indexList[2];
              this.hoverClass4 = indexList[2];
              this.twoList = this.bidPriceList[indexList[0]].children[indexList[1]].children;
            }
            this.$parent.$parent.getsearch(this.forsearch);
          })
        }
      })

    },
    // 地区编码
    getArealist(key, code) {
      const params = {
        key: key,
        code: code,
      };
      this.$fetch("/shoppingui/sysarea/listinfo", params, true)
        .then((res) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg);
          }
          if (key === "0") {
            this.provincelist = res.data.listinfop;
          }
          if (key === "1") {
            this.citylist = res.data.listinfoc;
            this.cityflg = false;
          }
          if (key === "2") {
            this.townlist = res.data.listinfot;
            this.townflg = false;
          }
        })
        .catch(() => {});
    },
    selectProvince(val) {
      this.activeClass99 = -1;
      this.hoverClass99 = -1;
      this.forsearch.city = "";
      this.citylist = [];
      this.citycode = "";
      this.townlist = [];
      this.towncode = "";
      this.townflg = true;
      this.getArealist("1", val);
      this.forsearch.provincecode = this.provincecode;
      this.$parent.$parent.getsearch(this.forsearch);
    },
    selectCity(val) {
      this.index99 = 88;
      this.activeClass99 = -1;
      this.hoverClass99 = -1;
      this.townlist = [];
      this.towncode = "";
      this.getArealist("2", val);
      this.forsearch.city = this.citycode;
      this.$parent.$parent.getsearch(this.forsearch);
    },
    selectTown(val) {
      if (this.towncode == null || this.towncode == "") {
        this.notifyError("请先选择市");
        return false;
      }
      this.activeClass99 = -1;
      this.hoverClass99 = -1;
      this.index99 = 88;
      this.forsearch.town = this.towncode;
      this.$parent.$parent.getsearch(this.forsearch);
    },
    // 省市县
    handleChange() {
      var loc = "";
      for (let i = 0; i < this.selectedOptions.length; i++) {
        loc += CodeToText[this.selectedOptions[i]];
      }
    },
    // type = 分类  拍卖状态等
    getactiveClass(type, index, e) {
      console.log(type,index,e,'@');
      this.fjzcdis = false;
      if (type == 1) {
        this.activeClass = index;
        this.hoverClass = index;
        this.forsearch.targettypecascader = e.code;
        this.$parent.$parent.getsearch(this.forsearch);
      } else if (type === 2) {
        this.activeClass2 = index;
        this.hoverClass2 = index;
        this.forsearch.outbidstate = e.type;
        this.$parent.$parent.getsearch(this.forsearch);
      } else if (type === 4) {
        // this.type2 = e.code;
        this.fjzcdis = true;
        this.activeClass = 1;
        this.hoverClass = 1;
        this.activeClass4 = index;
        this.hoverClass4 = index;
        this.forsearch.targettypecascader = e.code;
        this.$parent.$parent.getsearch(this.forsearch);
      } else if (type === 99) {
        this.activeClass99 = index;
        this.forsearch.provincecode = "";
        this.provincecode = "";
        this.forsearch.towncode = "";
        this.forsearch.citycode = "";
        (this.citycode = ""),
          (this.towncode = ""),
          (this.cityflg = true),
          (this.townflg = true),
          this.$parent.$parent.getsearch(this.forsearch);
      } else if (type === 5) {
        // this.type5 = e.code
        this.forsearch.targettypecascader = e.code
        this.oneListIsShow = false
        this.activeClass5 = index;
        this.hoverClass5 = index;
        this.$parent.$parent.getsearch(this.forsearch);
      } else {
        this.forsearch.outbidstate = "";
        this.forsearch.targettypecascader = "";
      }
    },
    gethoverClass(type, index,e) {
      // console.log(type,index,'##');
      //  this.fjzcdis = false
      if (type === 1) {
        if(e.children != 0){
          this.fjzcdis = true;
          this.twoList = e.children
          // console.log(this.oneList,'oneList');
        }else{
          this.fjzcdis = false
        }
        this.hoverClass = index;
      } else if (type === 2) {
        this.hoverClass2 = index;
        // this.fjzcdis = false
      } else if (type === 4) {
        this.hoverClass4 = index;
        // this.fjzcdis = false
      } else if (type === 99) {
        this.hoverClass99 = index;
        // this.fjzcdis = false
      } else if (type === "") {
        this.hoverClass = index;
        // this.fjzcdis = false
      } else if (type === 5) {
        if(e.children != 0){
        this.oneListIsShow = true
          this.oneList = e.children
        }else{
          this.oneListIsShow = false
        }
        this.hoverClass5 = index;
      }
    },
    closeClass(type) {
      //  this.fjzcdis = false
      if (type === 1) {
        //  if(index==1){
        //   this.fjzcdis = true
        // }
        this.hoverClass = -1;
      } else if (type === 2) {
        this.hoverClass2 = -1;
      } else if (type === 4) {
        this.hoverClass4 = -1;
      } else if (type === 99) {
        this.hoverClass99 = -1;
      } else if (type === 5) {
        this.hoverClass5 = -1;
      }
    },
    // 排序
    buttonsort(index, name) {
      this.showflg = true;
      this.activeClass3 = index;
      this.activeClass3show = !this.activeClass3show;
    },
    reset() {
      (this.forsearch = {
        outbidstate: "",
        targettypecascader: "",
        provincecode: "",
        city: "",
        town: "",
      }),
        (this.provincecode = ""),
        (this.citycode = ""),
        (this.towncode = ""),
        (this.selectedOptions = []), // 省市县
        (this.activeClass = ""), // 分类
        (this.hoverClass = ""), // 分类
        (this.activeClass2 = ""), // 拍卖状态
        (this.hoverClass2 = ""), // 拍卖状态
        (this.activeClass3 = ""), // 排序按钮
        (this.activeClass3show = true), // 排序按钮
        (this.showflg = false); // 排序按钮
      (this.cityflg = true),
        (this.townflg = true),
        this.$parent.$parent.getsearch(this.forsearch);
    },
  },
};
</script>

<style scoped lang="scss">
.type1 {
  cursor: pointer;
  line-height: 50px;
  color: #999999;
  border-bottom: 1px dashed #b9b8b8;
  & > span {
    display: inline-block;
    text-align: center;
    width: auto;
    line-height: 2;
    margin-right: 20px;
    padding: 0 5px 0 10px;
    &:nth-child(1) {
      text-align: left;
      width: 100px;
      margin-right: 30px !important;
    }
  }
  & > button {
    color: #999999;
    background: white;
    display: inline-block;
    width: 100px;
    height: 30px;
    border: 1px solid #999999;
    &:last-child {
      text-align: center;
      margin-top: 5px;
      margin-left: 435px;
      background: #f79c9c;
      border: 1px solid #f84f4f;
      border-radius: 5px;
      color: white;
      letter-spacing: 8px;
      text-indent: 8px; //文字居中
      width: 60px;
      &:active {
        background-image: none;
        outline: 0;
        box-shadow: 0 0 10px #f00 inset;
      }
    }
    .a {
      color: red;
      font-size: 16px;
      font-weight: 900;
    }
    .b {
      color: green;
      font-size: 16px;
      font-weight: 900;
    }
  }
}
.type2 {
  padding: 10px 0 10px 0;
  cursor: pointer;
  line-height: 50px;
  color: #999999;
  border-bottom: 1px dashed #b9b8b8;
  & > span {
    display: inline-block;
    text-align: center;
    width: 80px;
    line-height: 2;
    margin-right: 20px;
    &:nth-child(1) {
      text-align: left;
      width: 100px;
      margin-right: 30px !important;
    }
    &:nth-child(n + 3) {
      text-align: left;
      width: 180px;
      margin-right: 30px !important;
    }
  }
}
.myactived {
  font-weight: 700;
  width: 100px;
  text-align: center;
  text-decoration: none;
  color: #a56322;
  background-color: #f7efe8;
  cursor: pointer;
}
.myactivedbt {
  font-weight: 700;
  // width: 100px;
  text-align: center;
  text-decoration: none;
  color: #a56322;
  // background-color: #a56322;
}
.el-cascader {
  width: 300px;
}
.mybuindex {
  cursor: pointer;
  z-index: 50;
  font-weight: 700;
  color: #a56322;
  background-color: #f7efe8;
}
</style>
