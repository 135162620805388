<template>
  <div class="bidding">
    <div class="main">
      <el-card class="box-card">

        <div class="main-body">
          <search></search>
          <!-- 商品展示 -->
          <div class="bigbox">
            <div class="item" v-for="(i,index) in deptlist" :key="index" @click="iteminfo(i)">
              <div class="itemtitle">
                <el-image style="width: 210px; height: 150px;margin:5px;" :src="myPic(i.thumb)" fit="fill"
                          lazy></el-image>
              </div>
              <div class="itembody">
                <div style=" text-align: center;"><span :title="i.targetname">{{ i.targetname }}</span></div>
                <div class="pricex" style="margin-left:5%" v-if="i.shw == 1">
                  <span>挂牌价：</span>
                  <span></span>
                  <span>{{ i.price }}</span>
                  <span style="font-size:12px">{{ myUnit (i.unit) }}</span>
                </div>
                <div class="pricex" style="margin-left:5%" v-else>
                  <span>挂牌价：</span>
                  <span></span>
                  <span>{{ i.shwword }}</span>
                  <!--                            <span style="font-size:12px">{{myUnit(i.unit)}}</span>-->
                </div>
                <div class="pricex" v-if="i.outbidstate==2" style="margin-left:5%">
                  <span>成交价：</span>
                  <span></span>
                  <span>{{ i.outbidprice }}</span>
                  <span style="font-size:12px">{{ myUnit (i.unit) }}</span>
                </div>
                <div class="numx">
                  <!-- <span >所在地</span> -->
                  <span>{{ i.areaname }}</span>
                </div>
              </div>
              <div class="itemfoot">
                <div class="status " :class="myState(i.outbidstate)"
                     style="background:red">{{ typeName (i.outbidstate) }}
                </div>
                <div class="times">{{ myTime (i) }}</div>
              </div>
            </div>
          </div>
          <!-- 商品展示 -->
          <div class="block" align="right">
            <el-pagination
                @size-change="sizeChangeHandle"
                @current-change="currentChangeHandle"
                :current-page="pageIndex"
                :page-sizes="[20, 40, 60]"
                :page-size="pageSize"
                :total="totalPage"
                layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import DateUtils from "../../utils/DateUitls";
import searchtemp from './Bidding_search'

export default {
  components: {
    search: searchtemp
  },
  data () {
    return {
      activedInterval: null, // 定时器
      icaddr: require ('../../assets/imgs/ic_address.png'),
      deptlist: [],
      activeNames: ['1'],
      //分页数据
      dataForm: {
        productid: '',
        delivery: '',
        outbidstate: '',
        splitstate: ''
      },
      remaining: 0,
      systemTime: "", //获取服务器时间
      dataList: [],
      pageIndex: 1,
      pageSize: 20,
      totalPage: 1,
      dataListLoading: false,
      qualityTableStyle: {
        'background': 'whitesmoke',
        'font-weight': 'bold',
        'font-size': ' 12px',
        'font-family': "Microsoft YaHei",
        'color': '#333333',
        'text-align': 'center',
      },
      bidnames: '',//竞价场次名称
      timeDate: '',//开始结束时间
    }
  },
  activated () {
    //  java系统时间
    this.getNewTime ();
    if(!this.$route.query.code) {
      if (this.$route.query.goods && 'ok' === this.$route.query.goods) {
        this.getsearchCG ()
      } else {
        // 标的 列表
        this.getDataList ();
      }
    }
  },
  mounted () {
  },
  destroyed () {
    clearInterval (this.activedInterval)
  },
  methods: {
    ...mapGetters (["getUser", "getToken"]),
    //转换时间格式
    formateDate (datetime) {
      function addDateZero (num) {
        return (num < 10 ? "0" + num : num);
      }

      let d = new Date (datetime);
      let formatdatetime = d.getFullYear () + '-' + addDateZero (d.getMonth () + 1) + '-' + addDateZero (d.getDate ());
      return formatdatetime;
    },
    //搜索
    getSelect () {
      this.dataListLoading = true;
      this.getDataList ()
    },
    // 查询调用
    getsearch (e) {
      // if(e.targettypecascader.substr(0,2)!='11'){
      //   const index = e.targettypecascader.indexOf(',')
      //   e.targettypecascader = e.targettypecascader.substring(0,index)
      // }
      this.$fetch ('/shoppingui/bidShopping/targetPageList', {
        'page': 1,
        'limit': this.pageSize,
        'outbidstate': e.outbidstate,
        'targettypecascader': e.targettypecascader,
        'provincecode': e.provincecode,
        'city': e.city,
        'town': e.town,
      }, true).then ((data) => {
        if (data && data.code === 0) {
          this.getNewTime ()
          this.deptlist = data.data.list
          this.totalPage = data.data.total

        } else {
          this.dataList = []
          this.totalPage = 0
          this.$message ({
            message: data.msg,
            type: 'error',
            showClose: 'true'
          })
        }
      })
    },
    // 成功案例查询调用
    getsearchCG () {
      this.$fetch ('/shoppingui/bidShopping/targetPageList', {
        'page': 1,
        'limit': 20,
        'outbidstate': 2,
        'targettypecascader': '',
        'provincecode': '',
        'city': '',
        'town': '',
      }, true).then ((data) => {
        if (data && data.code === 0) {

          this.getNewTime ()
          this.deptlist = data.data.list
          this.totalPage = data.data.total

        } else {
          this.dataList = []
          this.totalPage = 0
          this.$message ({
            message: data.msg,
            type: 'error',
            showClose: 'true'
          })
        }
      })
    },
    // 获取数据列表
    getDataList () {

      this.$fetch ('/shoppingui/bidShopping/targetPageList', {
        'page': this.pageIndex,
        'limit': this.pageSize,
      }, true).then ((data) => {
        if (data && data.code === 0) {
          this.getNewTime ()
          this.deptlist = data.data.list
          this.totalPage = data.data.total

        } else {
          this.dataList = []
          this.totalPage = 0
          this.$message ({
            message: data.msg,
            type: 'error',
            showClose: 'true'
          })
        }
      })
    },
    startInterval () {
      if (this.activedInterval) {
        clearInterval (this.activedInterval)
      }
      this.activedInterval = setInterval (() => {
        this.systemTime = this.systemTime + 1000
        this.deptlist.forEach (i => {
          this.$set (this.deptlist, i.endtimed, this.Djs_timeList (i.endtime));
        })
      }, 1000)
    },
    Djs_timeList (itemEnd) {
      //  此处 itemEnd 的日期是年月日时分秒
      var endItem = new Date (itemEnd).getTime (); //获取列表传的截止时间，转成时间戳
      var nowItem = this.systemTime.getTime (); //获取当前时间
      var rightTime = endItem - nowItem; //截止时间减去当前时间
      var showTime = ""
      if (rightTime > 0) {
        //判断剩余倒计时时间如果大于0就执行倒计时否则就结束
        var dd = Math.floor (rightTime / 1000 / 60 / 60 / 24);
        var hh = Math.floor ((rightTime / 1000 / 60 / 60) % 24);
        var mm = Math.floor ((rightTime / 1000 / 60) % 60);
        var ss = Math.floor ((rightTime / 1000) % 60);
        showTime = dd + "天" + hh + "小时" + mm + "分" + ss + "秒";
      } else {
        showTime = "";
      }
      return showTime;
    },
    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      //调用查询信息接口
      this.getDataList ()
    },
    // 当前页
    currentChangeHandle (val) {
      this.pageIndex = val
      //调用查询信息接口
      this.getDataList ()
    },
    activated () {
      this.getDataList ()
    },
    gotoDetails (targetid) {
      let queryData = {};
      queryData.targetid = targetid;
      this.$router.push ({path: "/auction", query: queryData});
    },
    setProductid (val) {
      this.dataForm.productid = val
      //调用查询信息接口
      this.getDataList ()
    },
    setPelivery (val) {
      this.dataForm.delivery = val
      //调用查询信息接口
      this.getDataList ()
    },
    setOutbidstate (val) {
      this.dataForm.outbidstate = val
      //调用查询信息接口
      this.getDataList ()
    },
    setSplitstate (val) {
      this.dataForm.splitstate = val
      //调用查询信息接口
      this.getDataList ()
    },
    myState (state) {
      if (state == 1) {
        return 'redc'
      }
      if (state == 0) {
        return 'redc'
      }
      if (state == 2) {
        return 'orangec'
      }
      if (state == 3) {
        return 'blankc'
      }
      if (state == 4) {
        return 'blankc'
      }
      if (state == 5) {
        return 'greenc'
      }

    },
    myTime (i) {
      if (i.outbidstate == 1) {
        return '开始时间:' + i.begintime
      }
      if (i.outbidstate == 0) {
        return '开始时间:' + i.begintime
      }
      if (i.outbidstate == 2) {
        return '结束时间:' + i.finalendtime
      }
      if (i.outbidstate == 3) {
        return '结束时间:' + i.finalendtime
      }
      if (i.outbidstate == 4) {
        return '结束时间:' + i.finalendtime
      }
      if (i.outbidstate == 5) {
        return '开始时间:' + i.begintime
      }
    },
    typeName (state) {
      if (state == 1) {
        return '竞价中'
      }
      if (state == 0) {
        return '即将竞价'
      }
      if (state == 2) {
        return '已成交'
      }
      if (state == 3) {
        return '已流拍'
      }
      if (state == 4) {
        return '等待结果'
      }
      if (state == 5) {
        return '公示期'
      }
    },
    myPic (e) {
      if (e.length > 0) {
        return this.$ImgUrl + e[0]
      }
      return require ('../../assets/imgs/zanwutupian.jpg')
    },
    myDTime (e) {
      if (e.outbidstate == 1) {
        return '0';
        //  return this.getRemaining(e,e.endtime);
      } else if (e.outbidstate == 3 || e.outbidstate == 2 || e.outbidstate == 4) {
        return e.endtime
      } else if (e.outbidstate == 0) {
        return e.begintime
      } else {
        return '2021-01-01'
      }
    },
    iteminfo (e) {
      let queryData = {};
      this.$set (queryData, 'id', e.id)
      this.$set (queryData, 'targetid', e.id)
      this.$set (queryData, 'memberid', this.getUser ().id)
      if (e.splitstate == 0) {
        this.$router.push ({path: "/auction", query: queryData});
      } else {
        this.$router.push ({path: "/auction_split", query: queryData});
      }

    },
    // 获取服务器得时间
    getNewTime () {
      this.$post ("/shoppingui/bidShopping/getsysTime").then ((data) => {
        if (data.code == 0) {
          this.systemTime = new Date (data.data);
        } else {
          this.$message ({
            message: data.message,
            offset: 130,
            type: "error",
            showClose: "true",
          });
        }
      });
    },
    myUnit (e) {
      return e
      // if(e =='11'){
      //   return '元/吨'
      // }
      // if(e =='12'){
      //   return '元'
      // }
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/css/index.css";
@import "../../assets/css/page/Bidding.scss";

$redc: #c71622;
$greenc: #1b8f68;
$blankc: #8a8a8a;
$orangec: #f8a12b;

.bigbox {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  // justify-content:space-between;
  margin-top: 50px;
  background-color: #f7f7f7;
  // 禁用鼠标
  user-select: none;

  .item {
    background-color: #fff;
    transition: all .5s;
    width: calc(calc(100% / 5) - 10px);
    margin: 10px 5px;
    height: 320px;
    box-sizing: border-box;
    border: 3px solid #f7f7f7;
    // border: 3px solid #f7f7f7;
    &:hover {
      border: 3px solid red;
    }
  }
}

.item {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;

  .itemtitle {
    display: flex;
    width: 100%;
    height: 100%;
    flex: 4;
    //  border:3px solid yellowgreen;
    z-index: 20;

    & > img {
      flex: 1;
      width: 300px;
      height: 50%;
      height: 139px;
    }
  }

  .itembody {
    flex: 3;
    //  border:3px solid yellowgreen;
  }

  .itemfoot {
    flex: 1;
    // border:3px solid yellowgreen;
    height: 100%;
    background: #787878;
  }
}

.itembody {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  // align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  & > div {
    flex: 1;
    //  border:1px solid yellowgreen;
    & > span {
      display: inline-block;
      font-weight: 500;
      font-size: 18px;
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      & > last-child {
        text-align: right;
      }
    }
  }
}

.itemfoot {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: white;
  text-align: center;

  & > .states {
    flex: 5;
    // height: 100%;
    // display: grid;
    // justify-content:center;
    // align-items:center;
    // border:1px solid yellowgreen;
  }

  & > .times {
    flex: 9;
    font-size: 12px;
    // display: grid;
    // justify-content:center;
    // align-items:center;
    // border:1px solid yellowgreen;
  }
}

.times {
  font-size: 12px;
}

.pricex {
  // margin-left: -34%;
  // text-align: left;
  & > span {
    color: red;
  }

  & > :nth-child(1) {
    font-size: 12px;
    color: black;
  }

  & > :nth-child(2) {
    font-weight: 700;
    font-size: 12px;
  }

}

.numx {
  text-align: right;
  margin-right: 5%;

  & > span {
    font-size: 12px;
  }

  & > :nth-child(1) {
    font-size: 12px;
    color: black;
  }

  & > :nth-child(2) {
    color: red;
  }

  & > :nth-child(3) {
    font-size: 12px;
    color: black;
  }
}

.redc {
  width: 37%;
  height: 100%;
  line-height: 36px;
  background: $redc !important;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.greenc {
  width: 37%;
  height: 100%;
  line-height: 36px;
  background: $greenc !important;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.blankc {
  width: 37%;
  height: 100%;
  line-height: 36px;
  background: $blankc !important;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.orangec {
  width: 37%;
  height: 100%;
  line-height: 36px;
  background: $orangec !important;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
</style>
